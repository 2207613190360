import styles from '../AccountMenu.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'behavior/user';

const LogoutButton = ({ logoutDelay = 0 }) => {
  const dispatch = useDispatch();
  const timeoutIdRef = useRef(null);
  const ssoLogoutUrl = useSelector(state => state.settings.singleSignOnLogoutUrl);

  const handleClick = () => timeoutIdRef.current = setTimeout(() => dispatch(logout()), logoutDelay);

  useEffect(() => () => clearTimeout(timeoutIdRef.current), []);

  return (
    <div className={styles.bodyFooter}>
      <button className={`${linkStyles.link} ${linkStyles.arrowed}`} type="reset" onClick={event => [handleClick(), logoutSSO(ssoLogoutUrl)]}>
        <SimpleText textKey="Logout_LinkText" />
      </button>
    </div>
  );
};

LogoutButton.propTypes = {
  logoutDelay: PropTypes.number,
};

export default LogoutButton;

function logoutSSO(swUrl) {
  if (swUrl === null || swUrl === '') {
    console.log('No SSO Logout URL defined');
    return;
  }

  window.location.href = '/sso.logout';

  /*fetch('/auth/ssologout')
    .then(response => {
      console.log('SSO Logout');
    })
    .catch(() => [console.log('Failed to logout with CORS'), logoutSsoNoCors(swUrl)]);*/
}

function logoutSsoNoCors(swUrl) {
  fetch(swUrl, { mode: 'no-cors' })
    .then(response => {
      console.log('SSO Logout (mode: no-cors)');
    })
    .catch(() => console.log('Failed to logout (no-cors)'));
}
