import styles from '../document/Order.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { useDownload } from 'utils/hooks';
import { useDispatch } from 'react-redux';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { Subject, timer } from 'rxjs';
import { switchMap, mapTo, tap } from 'rxjs/operators';
import { useCultureName } from 'utils/hooks';

const ProductExportLink = ({ setReportLoading, msgId }) => {
  const options = [
    `ProductExport`,
  ];
  //ADU JLC basepath can be static
  const basePath = '/catalog/export';

  options.skipReplacingEmptyText = true;
  const cultureName = useCultureName();
  const culture = cultureName.toLowerCase();
  const testurl = '/' + culture + basePath;
  const { texts: [
    productExportText,
  ], loaded } = useSanaTexts(options);

  const download = useDownload();
  const dispatch = useDispatch();

  const loadingStateSubjRef = useRef();
  if (!loadingStateSubjRef.current)
    loadingStateSubjRef.current = new Subject();

  useEffect(() => {
    let prev;
    const sub = loadingStateSubjRef.current.pipe(
      switchMap(v => timer(v === ExportStates.Waiting || !prev || prev !== ExportStates.Waiting ? 450 : 2000).pipe(
        mapTo(v),
        tap(v => prev = v),
      )),
    ).subscribe(setReportLoading);

    return () => sub.unsubscribe();
  }, [setReportLoading]);

  if (!loaded)
    return <Placeholder className={styles.btnActionPlaceholder} />;

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    loadingStateSubjRef.current.next(ExportStates.Waiting);
    dispatch(setLoadingIndicator());

    download(testurl,
      () => void (loadingStateSubjRef.current.next(ExportStates.Success), dispatch(unsetLoadingIndicator())),
      () => void (loadingStateSubjRef.current.next(ExportStates.Failure), dispatch(unsetLoadingIndicator())),
    );
  };

  return (
    <a
      href={testurl}
      className={`${btnStyles.btn} ${btnStyles.btnSmall} ${styles.btnReport} ${styles.btnAction}`}
      rel="noopener noreferrer nofollow"
      target="_blank"
      onClick={handleClick}
      aria-describedby={msgId}
    >
      {productExportText}
    </a>
  );
};

ProductExportLink.propTypes = {
  productExportUrl: PropTypes.string.isRequired,
  setReportLoading: PropTypes.func.isRequired,
  msgId: PropTypes.string,
};

export default ProductExportLink;

export const ExportStates = {
  Success: 0,
  Waiting: 1,
  Failure: 2,
};
